import React, { FC } from 'react';
import { CarOffer } from 'src/cars/types';
import useTranslation from 'providers/translations/use-translations';
import Tabs from 'components/common/tabs';
import CarInsurancesTab from 'components/car-details/car-insurances-tab';
import CarEquipmentTab from 'components/car-details/car-equipment-tab';
import CarTermsTab from 'components/car-details/car-terms-tab';
import useCarsApi from 'providers/cars-api/use-cars-api';
import classNames from 'classnames';
import { AdditionalOfferOptions } from 'src/booking-engine/types';
import styles from './offer.module.scss';
import CarStationsTab from '../../components/car-details/car-stations-tab';

type Props = {
  offer: CarOffer;
  previewOnly: boolean;
  printTargetRef?: any;
  additionalOptions?: AdditionalOfferOptions;
};

const OfferDetails: FC<Props> = ({ offer, previewOnly, printTargetRef, additionalOptions }) => {
  const { t } = useTranslation();
  const { subpage } = useCarsApi();

  return (
    <div className={classNames(styles.offerDetails, 'offer_offerDetails')}>
      <Tabs className={classNames('offer_offerDetails_tabs', 'noPrint')}>
        <div data-tab={t('0nc_offer_info')}>
          <CarEquipmentTab offer={offer} printTargetRef={printTargetRef} />
        </div>

        <div data-tab={t('insurances')}>
          <CarInsurancesTab offer={offer} />
        </div>

        {!previewOnly && (
          <div data-tab={t('0nc_possible_stations')} data-disabled={subpage !== 'offerlist'}>
            <CarStationsTab offer={offer} />
          </div>
        )}

        <div data-tab={t('0nc_tab_terms_cond')}>
          <CarTermsTab offer={offer} additionalOptions={additionalOptions} />
        </div>
      </Tabs>
    </div>
  );
};

OfferDetails.defaultProps = {
  printTargetRef: null,
  additionalOptions: {}
};

export default OfferDetails;
