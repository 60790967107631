import classNames from 'classnames';
import React, { FC, ReactNode, useState } from 'react';
import CarIcon from 'components/common/car-icon';
import BoxOpener from 'modules/booking-form/box-opener';

import styles from './common.module.scss';

export type LevelType = 'error' | 'info' | 'default' | 'warning' | 'success' | 'neutral';

type Props = {
  title?: string;
  icon?: string;
  level?: LevelType;
  children?: ReactNode;
  className?: string;
  showOpener?: boolean;
  isOpened?: boolean;
};

const Infobox: FC<Props> = (props) => {
  const [opened, setValue] = useState(props.isOpened);
  return (
    (props.children || props.title) && (
      <div
        className={classNames(styles.infobox, props.className, styles[`infobox__${props.level}`])}
      >
        {props.icon && <CarIcon size="18px" icon={props.icon} />}
        <div>
          {(props.title || props.showOpener) && (
            <div className={styles.titleSection}>
              {props.title && <h3 className={styles.infobox__title}>{props.title}</h3>}
              {props.showOpener && (
                <BoxOpener className={styles.box__opener} isOpened={opened} onClick={setValue} />
              )}
            </div>
          )}
          {props.children && (
            <div
              className={classNames(styles.infobox__content, {
                [styles.box__is_closed]: !opened
              })}
            >
              {props.children}
            </div>
          )}
        </div>
      </div>
    )
  );
};

Infobox.defaultProps = {
  title: '',
  icon: null,
  level: 'default',
  children: '',
  className: undefined,
  isOpened: true,
  showOpener: false
};

export default Infobox;
