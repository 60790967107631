import React, { FC, memo, useEffect, useState } from 'react';
import classNames from 'classnames';
import CarIcon from 'components/common/car-icon';
import useScreenDetect from 'providers/screen-detect/use-screen-detect';
import styles from '../header.module.scss';

interface Props {
  nr: number;
  label: string;
  current: number;
  disabled?: boolean;
  onClick?: () => void;
}

const SingleStep: FC<Props> = (props) => {
  const { isMobile } = useScreenDetect();
  const [hover, setHover] = useState(false);
  const [current, setCurrent] = useState(props.current === props.nr);
  const [past, setPast] = useState(props.current > props.nr);

  const btnClick = () => {
    if (!past || props.disabled) return;
    props.onClick();
  };

  useEffect(() => {
    setCurrent(props.current === props.nr);
    setPast(props.current > props.nr);
  }, [props.nr, props.label]);

  return (
    <li className={classNames(styles.steps__item)}>
      <button
        type="button"
        className={classNames(styles.steps__item__btn, {
          [styles['steps__item__btn--highlight']]: current || (hover && past)
        })}
        onClick={btnClick}
        style={{
          cursor: past ? 'pointer' : undefined
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        {past ? (
          <CarIcon
            icon="tick"
            size="12px"
            className={classNames(styles.steps__icon, {
              [styles['steps__icon--highlight']]: current || (hover && past)
            })}
          />
        ) : (
          <span
            className={classNames(styles.steps__number, {
              [styles['steps__number--highlight']]: current || (hover && past)
            })}
          >
            {props.nr}
          </span>
        )}
        {(!isMobile || current) && <span>{props.label}</span>}
      </button>
    </li>
  );
};

SingleStep.defaultProps = {
  onClick: () => null,
  disabled: false
};

export default memo(SingleStep);
