import React, { FC, memo, useState } from 'react';
import classNames from 'classnames';
import CarIcon from 'components/common/car-icon';
import useTranslation from 'providers/translations/use-translations';
import { CarOffer } from 'src/cars/types';

import styles from './offer.module.scss';

type Props = {
  offer: CarOffer;
  className?: string;
  limitTo?: string;
  noLabels?: boolean;
};

const Equip: FC<Props> = ({ offer, className, limitTo, noLabels }) => {
  const { t } = useTranslation();
  const [equipList] = useState(() => {
    switch (limitTo) {
      case 'offersWidget':
        return ['ac', 'adults', 'doors', 'electric'];
      case 'categorySlider':
        return ['adults', 'doors'];
      default:
        return [
          'ac',
          'adults',
          'doors',
          'manualTransmission',
          'automaticTransmission',
          'electric',
          'gps',
          'wifi',
          'additionalDriver',
          'hasNoAirportFee',
          'noLocalFee'
        ];
    }
  });
  return (
    <div className={classNames(className, styles.offerEquip, 'offerEquip')}>
      {equipList.indexOf('adults') > -1 && offer.adults && (
        <CarIcon
          className={className}
          icon="user-o"
          label={String(offer.adults + offer.children)}
          size="18px"
        />
      )}
      {equipList.indexOf('doors') > -1 && offer.doors && (
        <CarIcon className={className} icon="doors" label={offer.doors} size="18px" />
      )}
      {equipList.indexOf('ac') > -1 && offer.equip.includes('ac') && (
        <CarIcon
          className={className}
          icon="ac"
          label={noLabels !== true && t('a_klima')}
          size="18px"
        />
      )}
      {equipList.indexOf('manualTransmission') > -1 &&
        offer.equip.includes('manualTransmission') && (
          <CarIcon
            className={className}
            icon="manual-gear"
            label={noLabels !== true && t('0nc_gear_m')}
            size="18px"
          />
        )}
      {equipList.indexOf('electric') > -1 && offer.equip.includes('electric') && (
        <CarIcon
          className={className}
          icon="power-cord"
          label={noLabels !== true && t(`0nc_fuel_${offer.fuel}`, offer.fuel)}
          size="18px"
        />
      )}
      {equipList.indexOf('automaticTransmission') > -1 &&
        offer.equip.includes('automaticTransmission') && (
          <CarIcon
            className={className}
            icon="automatic-gear"
            label={noLabels !== true && t('0nc_gear_a')}
            size="18px"
          />
        )}
      {equipList.indexOf('additionalDriver') > -1 && offer.equip.includes('additionalDriver') && (
        <CarIcon
          className={className}
          icon="user-plus"
          label={noLabels !== true && t('additional_driver')}
          size="18px"
        />
      )}
      {equipList.indexOf('gps') > -1 && offer.equip.includes('gps') && (
        <CarIcon
          className={className}
          icon="nav"
          label={noLabels !== true && t('a_gps')}
          size="18px"
        />
      )}
      {equipList.indexOf('wifi') > -1 && offer.equip.includes('wifi') && (
        <CarIcon
          className={className}
          icon="wifi"
          label={noLabels !== true && t('wifi', 'WIFI')}
          size="18px"
        />
      )}
      {equipList.indexOf('hasNoAirportFee') > -1 && offer.equip.includes('hasNoAirportFee') && (
        <CarIcon
          className={className}
          icon="money"
          label={noLabels !== true && t('0nc_airport_fees_included')}
          size="18px"
        />
      )}
      {equipList.indexOf('noLocalFee') > -1 &&
        !offer.equip.includes('hasNoAirportFee') &&
        offer.equip.includes('noLocalFee') && (
          <CarIcon
            className={className}
            icon="money"
            label={noLabels !== true && t('local_tax_inc')}
            size="18px"
          />
        )}
    </div>
  );
};

Equip.defaultProps = {
  className: undefined,
  limitTo: '',
  noLabels: false
};

export default memo(Equip);
