import React from 'react';
import classNames from 'classnames';

import styles from './common.module.scss';

interface SpinnerProps {
  className?: string;
  size?: 'default' | 'small';
}

const Spinner: React.FC<SpinnerProps> = (props) => (
  <div
    className={classNames(props.className, styles.spinnerContainer, {
      [styles.small]: props.size === 'small'
    })}
  >
    {[1, 2, 3, 4, 5].map((i) => (
      <div key={i} className={styles.spinnerRectangle} />
    ))}
  </div>
);

Spinner.defaultProps = {
  size: 'default',
  className: ''
};

export default Spinner;
