import React, { FC, ReactNode } from 'react';
import CarIcon from 'components/common/car-icon';
import styles from './details.module.scss';

interface Props {
  legend?: string;
  label?: string;
  labelIcon?: string;
  content?: string;
  contentIcon?: string;
  description?: string;
  descriptionMinor?: string;
  children?: ReactNode;
}

const CarEquipmentItem: FC<Props> = (props) => (
  <>
    {props.legend && <span className={styles.carDetails__legend}>{props.legend}</span>}
    {(props.label || props.content) && (
      <>
        <span className={styles.carDetails__item}>
          {props.label && (
            <span className={styles.carDetails__item_label}>
              {props.labelIcon && <CarIcon icon={props.labelIcon} size="18px" />}
              {props.label}
            </span>
          )}

          {(props.content || props.contentIcon) && (
            <span className={styles.carDetails__item_value}>
              {props.contentIcon && <CarIcon icon={props.contentIcon} size="12px" />}
              {props.content}
            </span>
          )}
        </span>
      </>
    )}
    {props.children}
    {props.description && (
      <span
        className={styles.carDetails__description}
        dangerouslySetInnerHTML={{
          __html: `${props.description}
            <span className=${styles.carDetails__description_minor}>
              ${props.descriptionMinor}
            </span>`
        }}
      />
    )}
  </>
);

CarEquipmentItem.defaultProps = {
  legend: '',
  label: '',
  labelIcon: '',
  content: '',
  contentIcon: '',
  description: '',
  descriptionMinor: '',
  children: null
};

export default CarEquipmentItem;
