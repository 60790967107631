import CarIcon from 'components/common/car-icon';
import classNames from 'classnames';
import PartnerLogo from 'components/common/partner-logo';
import Button from 'components/forms/button';
import React, { FC, memo, useRef, useState } from 'react';
import useTranslation from 'providers/translations/use-translations';
import { ucFirst } from 'src/utils/string';
import { CarOffer } from 'src/cars/types';
import Price from 'components/common/price';
import CompareBtn from 'components/car-compare/compare-btn';
import useDevToolsContext from 'providers/dev-tools/use-dev-tools-context';
import useCarsApi from 'providers/cars-api/use-cars-api';
import JsonPreview from 'modules/dev-tools/json-preview';
import useScreenDetect from 'providers/screen-detect/use-screen-detect';
import { goToBookingPage } from 'src/url-manager';
import { FadeIn } from 'components/common/animations';
import useNonInitialEffect from 'hooks/use-non-initial-effect';
import { AdditionalOfferOptions, CalculatedPricesType } from 'src/booking-engine/types';
import OverlayWrapper from 'components/common/overlay-wrapper';
import { wsInfo } from 'src/logger';
import useOffersContext from 'providers/offers/use-offers-context';
import { limitedKmLabel } from 'src/cars/car-tools';
import OfferAdvantages from './offer-advantages';
import OfferDetails from './offer-details';
import styles from './offer.module.scss';
import Equip from './equip';
import InsuranceOverview from './insurance-overview';
import ScoreLabel from './score-label';
import LocationLabel from './location-label';
import DiscountLabel from './discountLabel';

const markWithColor = (type: 'fuel_policy' | 'distance_policy', value: any) => {
  switch (type) {
    case 'fuel_policy':
      if (['FTF', 'PFTE', 'PFTF', 'ETE', 'FTE', 'FTF', 'HTE', 'QTE', 'SAME'].includes(value)) {
        return 'success';
      }
      return 'default';
    case 'distance_policy':
      if (value > 0) {
        return 'warning';
      }
      return 'success';
    default:
      return 'default';
  }
};

type Props = {
  offer: CarOffer;
  previewOnly?: boolean;
  className?: string;
  withDetails?: boolean;
  overridePrices?: CalculatedPricesType;
  additionalOptions?: AdditionalOfferOptions;
};
const SingleOffer: FC<Props> = ({
  offer,
  previewOnly,
  className,
  withDetails,
  overridePrices,
  additionalOptions
}) => {
  const { inhouse, dataPreview } = useDevToolsContext();
  const { isMobile } = useScreenDetect();
  const { searchId } = useOffersContext();
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation();
  const { subpage } = useCarsApi();
  const [noImage, setNoImage] = useState(false);
  const offerRef = useRef(null);

  const markFuelPolicy = markWithColor('fuel_policy', offer.fuelPolicy);
  const markDistancePolicy = markWithColor('distance_policy', offer.includedKm !== 0);
  useNonInitialEffect(() => {
    if (!expanded) return;
    wsInfo('userInteraction', 'Expand car details', {
      offerId: offer.offerId
    });
  }, [expanded]);

  if (!!searchId && searchId !== offer.sessionId) return null;

  return (
    <>
      <div
        ref={offerRef}
        className={classNames('offer', styles.singleOffer, className, {
          [styles['isMobile']]: isMobile,
          [styles['singleOffer--previewOnly']]: previewOnly
        })}
      >
        <div className={classNames(styles.offerHeading)}>
          <span className={styles.carType}>{ucFirst(t(offer.carclass))}</span>
          <div className={styles.carNameContainer}>
            <span className={styles.carName}>{offer.name}</span>
            <span className={styles.orSimiliar}>{t('sipp_or_similiar')}</span>
          </div>
        </div>
        <Equip offer={offer} className={styles.carIcon} />
        <div className={classNames(styles.offerPolicies)}>
          <div className={classNames(styles.policyFuel, styles.policyContainer)}>
            <CarIcon
              icon="gasoline-pump-3"
              size="30px"
              className={classNames(styles.policyIcon, {
                [styles['policyIcon--warning']]: markFuelPolicy === 'warning',
                [styles['policyIcon--success']]: markFuelPolicy === 'success'
              })}
              style={{ color: markFuelPolicy }}
            />
            <span className={styles.policyDescription}>
              <span className={styles.policyLabel}>{`${t('fuel_policy')}:`}</span>
              <span
                className={classNames(styles.policyValue, {
                  [styles['policyValue--warning']]: markFuelPolicy === 'warning',
                  [styles['policyValue--success']]: markFuelPolicy === 'success'
                })}
              >
                {offer.fuelPolicy &&
                  t(`fuel_policy_${offer.fuelPolicy.toLowerCase()}`, offer.fuelPolicy)}
                {!offer.fuelPolicy && t('fuel_policy_unkown')}
              </span>
            </span>
          </div>
          <div className={classNames(styles.policyDistance, styles.policyContainer)}>
            <CarIcon
              icon="distance"
              size="30px"
              className={classNames(styles.policyIcon, {
                [styles['policyIcon--warning']]: markDistancePolicy === 'warning',
                [styles['policyIcon--success']]: markDistancePolicy === 'success'
              })}
            />
            <span className={styles.policyDescription}>
              <span className={styles.policyLabel}>{`${t('0nc_limited_km')}:`}</span>
              <span
                className={classNames(styles.policyValue, {
                  [styles['policyValue--warning']]: markDistancePolicy === 'warning',
                  [styles['policyValue--success']]: markDistancePolicy === 'success'
                })}
              >
                {offer.includedKm === 0 && t('0nc_unlimited_km')}
                {offer.includedKm > 0 && limitedKmLabel(offer, t)}
                {offer.includedKm < 0 && t('additional_km_cost')}
              </span>
            </span>
          </div>
        </div>

        <div className={classNames(styles.offerPicture)}>
          {noImage ? (
            <>
              <img alt={offer.name} src="/img/no-car-image.png" />
              <span>{t('no_car_image')}</span>
            </>
          ) : (
            <img
              alt={offer.name}
              src={offer.image.replace('75x75', '200x167')}
              onError={() => setNoImage(true)}
            />
          )}
        </div>
        <div className={classNames(styles.underlineSplitter1, styles.underlineSplitter)} />

        <div className={classNames(styles.underlineSplitter3, styles.underlineSplitter)} />
        <div className={styles.offerScore}>
          <ScoreLabel offer={offer} className={styles.equipBar__score} />
        </div>
        <div
          className={classNames(styles.offerPartner, {
            [styles['offerPartner--rightBottom']]: previewOnly
          })}
        >
          <PartnerLogo
            name={offer.partner}
            splitLine={offer.supplier && offer.supplier !== offer.partner}
            label="partner"
            textOnly
          />
          {offer.showSupplierLogo && offer.supplier && offer.supplier !== offer.partner && (
            <PartnerLogo label="supplier" width={65} height={20} name={offer.supplier} textOnly />
          )}
          <OfferAdvantages offer={offer} />
        </div>

        {!previewOnly && (
          <>
            <div className={classNames(styles.offerCompare, 'offerCompare', 'noPrint')}>
              <CompareBtn offer={offer} />
            </div>

            <div className={classNames(styles.underlineSplitter2, styles.underlineSplitter)} />
            <div className={classNames(styles.offerLabels)}>
              <LocationLabel types={offer.pickupStation.locationTypes} />
            </div>
          </>
        )}
        {!isMobile && withDetails && (
          <div className={classNames(styles.offerMore, 'offer_moreBtn', 'noPrint')}>
            <Button
              className={styles.moreBtn}
              variant="tertiary"
              size="medium"
              onClick={() => setExpanded(!expanded)}
            >
              <div className={styles.moreBtn__content}>
                <span className={styles.moreBtn__title}>
                  <span className={styles.moreBtn__label}>{t('mobile_extended_info')}</span>
                  <CarIcon
                    className={styles.moreBtn__info}
                    icon={expanded ? 'chevron-up' : 'chevron-down'}
                    size="13px"
                  />
                </span>
              </div>
            </Button>
          </div>
        )}

        {isMobile && withDetails && (
          <div className={classNames(styles.offerMore, 'offer_moreBtn', 'noPrint')}>
            <Button
              className={styles.moreBtn}
              variant="tertiary"
              size="medium"
              onClick={() => setExpanded(true)}
            >
              <div className={styles.moreBtn__content}>
                <span className={styles.moreBtn__title}>
                  <span className={styles.moreBtn__label}>{t('mobile_extended_info')}</span>
                  <CarIcon
                    className={styles.moreBtn__info}
                    icon={expanded ? 'chevron-up' : 'chevron-down'}
                    size="13px"
                  />
                </span>
              </div>
            </Button>
          </div>
        )}

        {!previewOnly && (
          <>
            <div className={styles.offerButton}>
              <Button
                className={classNames(styles.bookBtn, 'offer_bookBtn', 'noPrint')}
                variant="primary"
                size="large"
                onClick={() => {
                  goToBookingPage(offer.offerId, inhouse);
                  wsInfo('userInteraction', 'Offer button clicked', {
                    offerId: offer.offerId
                  });
                }}
              >
                {!isMobile && <span>{t('0nc_choosethis')}</span>}
                <CarIcon icon="chevron-right-thin" size="30px" />
              </Button>
            </div>
          </>
        )}

        <div className={styles.offerPrices}>
          {(offer.prepaid === true || offer.status) && (
            <div className={styles.offerAdditionalInfos}>
              {offer.prepaid === true && (
                <div
                  className={styles.offerPrepaid}
                  data-html
                  data-tooltip-id="offer_prepaid_info"
                  data-tooltip-html={`<div>${t(
                    `prepaid_rates_info_${offer.partner.toLowerCase()}`,
                    t('prepaid_rates_info')
                  )}</div>`}
                >
                  <span className={styles.offerPrepaid__label}>{t('tariff_type')}</span>
                  <span className={styles.offerPrepaid__status}>
                    {t('0nc_offer_prepaid')}
                    <CarIcon size="12px" icon="info" />
                  </span>
                </div>
              )}

              {offer.status && (
                <div
                  className={styles.offerStatus}
                  data-html
                  data-tooltip-html={`<div>${t(
                    `0nc_offer_status_${offer.status.toLowerCase()}_desc`
                  )}</div>`}
                  data-tooltip-id="offer_status_info"
                >
                  <span className={styles.offerStatus__label}>{t('0nc_offer_status')}</span>
                  <span
                    className={classNames(styles.offerStatus__status, {
                      [styles['offerStatus__label--available']]: offer.status.toLowerCase() === 'ok'
                    })}
                  >
                    {t(`0nc_offer_status_${offer.status.toLowerCase()}`)}
                    <CarIcon size="12px" icon="info" />
                  </span>
                </div>
              )}

              {offer.discount && <DiscountLabel discount={offer.discount} />}
            </div>
          )}
          <div className={styles.offerPrices__pack}>
            {(!offer.priceExchanged || subpage !== 'offerlist') && (
              <Price
                className={styles.offerPrice}
                price={(overridePrices && overridePrices.total) || offer.price}
                currency={offer.currency}
              />
            )}
            {offer.priceExchanged > 0 && (
              <span data-tooltip-id="tooltip_price_info">
                <Price
                  className={classNames(styles.offerPrice, styles.offerPrice__exchanged)}
                  price={
                    (overridePrices && overridePrices.totalInAgentCurrency) || offer.priceExchanged
                  }
                  currency={offer.currencyExchanged}
                  prefix={t('about_abbr', '~')}
                />
              </span>
            )}
            {!offer.priceExchanged && (
              <span className={styles.offerPricePerDay}>
                <Price
                  price={(overridePrices && overridePrices.totalPerDay) || offer.pricePerDay}
                  currency={offer.currency}
                />
                <span>
                  &nbsp;
                  {t('price_day')}
                </span>
              </span>
            )}
            {offer.priceExchanged > 0 && (
              <span className={styles.offerPricePerDay}>
                <Price
                  price={
                    (overridePrices && overridePrices.totalPerDayInAgentCurrency) ||
                    offer.pricePerDayExchanged
                  }
                  currency={offer.currencyExchanged}
                  prefix={t('about_abbr', '~')}
                />
                <span>
                  &nbsp;
                  {t('price_day')}
                </span>
              </span>
            )}
          </div>
        </div>

        <InsuranceOverview
          offer={offer}
          className={classNames(styles.offerInsurances, {
            [styles['offerInsurances--inline']]: previewOnly
          })}
        />
        {withDetails && (
          <OverlayWrapper
            title={t('mobile_extended_info')}
            open={expanded}
            closeBtn
            inUse={isMobile}
            className={styles.detailsOverlay}
            onClose={() => setExpanded(false)}
          >
            <FadeIn open={expanded}>
              <OfferDetails
                offer={offer}
                previewOnly={previewOnly}
                printTargetRef={offerRef}
                additionalOptions={additionalOptions}
              />
            </FadeIn>
          </OverlayWrapper>
        )}
      </div>
      {dataPreview && (
        <JsonPreview className={styles.offerJson} data={offer} title="Show offer json" />
      )}
    </>
  );
};
SingleOffer.defaultProps = {
  previewOnly: false,
  className: undefined,
  withDetails: true,
  overridePrices: {},
  additionalOptions: {}
};

export default memo(SingleOffer);
