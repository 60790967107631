import React, { FC, memo } from 'react';

type Props = {
  countryCode: string;
  className?: string;
  size?: string;
  width?: string;
  height?: string;
};

const CountryFlag: FC<Props> = (props) => {
  const src = `/img/flags/1x1/${props.countryCode}.svg`;
  const styles = {
    width: props.width && props.height ? props.width : props.size,
    height: props.height && props.width ? props.height : props.size
  };

  return (
    <img className={props.className} src={src} alt={props.countryCode} style={{ ...styles }} />
  );
};

CountryFlag.defaultProps = {
  className: '',
  size: '24px',
  height: '',
  width: ''
};

export default memo(CountryFlag);
