import TickList from 'components/common/tick-list';
import React, { FC, memo } from 'react';
import { CarOffer } from 'src/cars/types';
import CarIcon from 'components/common/car-icon';
import useTranslation from 'providers/translations/use-translations';
import { currencyToSymbol, priceFormat } from 'src/utils/string';
import useOffersContext from 'providers/offers/use-offers-context';
import Infobox from 'components/common/infobox';
import useCarsApi from 'providers/cars-api/use-cars-api';
import Button from 'components/forms/button';

import classNames from 'classnames';
import styles from './offer.module.scss';

type Props = {
  offer: CarOffer;
  className?: string;
  short?: boolean;
};

const InsuranceOverview: FC<Props> = ({ offer, className, short }) => {
  if (!offer.insurances || offer.insurances.length === 0) return null;
  const { t } = useTranslation();
  const { updateFilter, pricesStartsFrom } = useOffersContext();
  const { subpage } = useCarsApi();

  const filterChange = () => {
    updateFilter({
      type: 'carScore',
      value: ['premium']
    });
  };

  const getName = (insurance, partner) => {
    const clearKey =
      insurance.key.startsWith('SX_') || insurance.noKeyDisplay
        ? ''
        : ` (${insurance.key.replace('_', '')})`;

    return `${t(
      `insurance_${partner.toLowerCase()}_${insurance.key.toLowerCase()}`,
      t(
        `insurance_${insurance.key.toLowerCase()}`,
        insurance.name || insurance.description || insurance.key
      )
    )} ${!short ? clearKey : ''}`;
  };

  const sortInsurances = (insurances) => {
    const sorted = insurances.sort((a, b) => {
      const first = getName(a, offer.partner).toUpperCase();
      const latter = getName(b, offer.partner).toUpperCase();
      if (first < latter) return -1;
      if (first > latter) return 1;
      return 0;
    });
    return sorted;
  };

  const prepareListing = () => {
    const insurances = offer.insurances.filter((item) => item.properties.included === true);
    return sortInsurances(insurances);
  };

  const getLiabilityInfo = (insurance) => {
    if (insurance.liability) {
      return `${priceFormat(insurance.liability)} ${currencyToSymbol(insurance.currency)}`;
    }
    return undefined;
  };

  const getExcessInfo = (insurance) => {
    if (insurance.properties) {
      if (insurance.properties.excessRefund) {
        return {
          label: t('0nc_excess_refund'),
          icon: 'tick',
          level: 'success'
        };
      }

      if (insurance.properties.contribution === false) {
        return {
          label: t('insurance_without_excess'),
          icon: 'tick',
          level: 'success'
        };
      }

      if (insurance.properties.contribution === true) {
        return {
          label: t('insurance_with_excess'),
          icon: 'exclamation-2',
          level: 'danger'
        };
      }
    }
    return {
      icon: 'tick',
      level: 'success'
    };
  };

  const getExcessValue = (insurance) => {
    // return undefined; // temp turn off
    if (insurance.excessAmount && insurance.properties && insurance.properties.contribution) {
      return `${priceFormat(insurance.excessAmount)} ${currencyToSymbol(insurance.currency)}`;
    }
    return undefined;
  };

  const getDeductibleValue = (insurance) => {
    return undefined; // temp turn off
    if (insurance.deductible) {
      return `${priceFormat(insurance.deductible)} ${currencyToSymbol(insurance.currency)}`;
    }
    return undefined;
  };

  const getRCTInfo = (insurance) => {
    const message = [];
    if (insurance.properties) {
      if (insurance.properties.glass) {
        message.push(`${t('insurance_glass_tires')}`);
      }
      if (insurance.properties.roof) {
        message.push(`${t('insurance_roof')}`);
      }
      if (insurance.properties.chassis) {
        message.push(`${t('insurance_chassis')}`);
      }
    }
    return message.length ? `(${message.join(',')})` : undefined;
  };
  return (
    <>
      <TickList className={className} circled>
        {prepareListing().map((item) => {
          const l = getExcessInfo(item).level;
          return (
            <li
              key={item.key}
              data-icon={getExcessInfo(item) ? getExcessInfo(item).icon : ''}
              data-icon-className={classNames(
                styles.insuranceItem__listItem,
                'common_insuranceItem__listItem__icon',
                {
                  'common_insuranceItem__listItem--danger': l === 'danger',
                  'common_insuranceItem__listItem--warning': l === 'warning',
                  [styles['insuranceItem__listItem--danger']]: l === 'danger',
                  [styles['insuranceItem__listItem--warn']]: l === 'warning'
                }
              )}
            >
              <span className={styles.insuranceItem__container}>
                {getName(item, offer.partner) && (
                  <span
                    className={styles.insuranceItem__name}
                    dangerouslySetInnerHTML={{
                      __html: getName(item, offer.partner)
                    }}
                  />
                )}

                {getRCTInfo(item) && (
                  <span
                    className={styles.insuranceItem__RCTInfo}
                    dangerouslySetInnerHTML={{
                      __html: getRCTInfo(item)
                    }}
                  />
                )}

                {getLiabilityInfo(item) && (
                  <span
                    className={styles.insuranceItem__liability}
                    dangerouslySetInnerHTML={{
                      __html: getLiabilityInfo(item)
                    }}
                  />
                )}

                {getExcessInfo(item).label && (
                  <span
                    className={classNames(styles.insuranceItem__excessInfo, {
                      [styles['insuranceItem__excessInfo--danger']]: l === 'danger',
                      [styles['insuranceItem__excessInfo--warn']]: l === 'warning'
                    })}
                    dangerouslySetInnerHTML={{
                      __html: getExcessInfo(item).label
                    }}
                  />
                )}
                {getExcessValue(item) && (
                  <span
                    className={styles.insuranceItem__excessValue}
                    dangerouslySetInnerHTML={{
                      __html: getExcessValue(item)
                    }}
                  />
                )}

                {getDeductibleValue(item) && (
                  <span
                    className={styles.insuranceItem__deductibleValue}
                    dangerouslySetInnerHTML={{
                      __html: getDeductibleValue(item)
                    }}
                  />
                )}
              </span>
            </li>
          );
        })}
      </TickList>
      {!short && (
        <>
          {subpage === 'offerlist' &&
            offer.score < 8.5 &&
            pricesStartsFrom['carScore'] &&
            pricesStartsFrom['carScore']['premium'] && (
              <Infobox
                className={styles.carDetails__infobox}
                level="info"
                title={t('our_recommendation')}
              >
                {t('deposit_avoid_costs')}
                <br />
                <Button variant="primary" onClick={() => filterChange()}>
                  {t('show_offers_without_excess')}
                </Button>
              </Infobox>
            )}
          <div className={styles.insurancesDesc}>
            <div className={styles.insurancesDesc__set}>
              <span className={styles.insurancesDesc__caption}>
                <CarIcon icon="info" size="14px" />
                {t('insurance_without_excess')}
              </span>
              <span className={styles.insurancesDesc__desc}>{t('0nc_without_excess_desc')}</span>
            </div>
            <div className={styles.insurancesDesc__set}>
              <span className={styles.insurancesDesc__caption}>
                <CarIcon icon="info" size="14px" />
                {t('0nc_excess_refund')}
              </span>
              <span className={styles.insurancesDesc__desc}>{t('0nc_excess_refund_desc')}</span>
            </div>
            <div className={styles.insurancesDesc__set}>
              <span className={styles.insurancesDesc__caption}>
                <CarIcon icon="info" size="14px" />
                {t('insurance_with_excess')}
              </span>
              <span className={styles.insurancesDesc__desc}>{t('0nc_with_excess_desc')}</span>
            </div>
          </div>
        </>
      )}
    </>
  );
};

InsuranceOverview.defaultProps = {
  className: '',
  short: true
};

export default memo(InsuranceOverview);
