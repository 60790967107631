import React, { FC, useEffect } from 'react';
import { CarOffer } from 'src/cars/types';
import { ucFirst, currencyToSymbol, priceFormat, combineString } from 'src/utils/string';
import ReactDOMServer from 'react-dom/server';
import PartnerLogo from 'components/common/partner-logo';
import { datetimeFormat } from 'src/utils/date-time';
import useTranslation from 'providers/translations/use-translations';
import classNames from 'classnames';
import Price from 'components/common/price';
import useCarsApi from 'providers/cars-api/use-cars-api';
import CarIcon from 'components/common/car-icon';
import { wsInfo } from 'src/logger';

import PrintWrapper from 'components/car-print';
import { limitedKmLabel } from 'src/cars/car-tools';
import CarEquipmentItem from './car-equipment-item';
import styles from './details.module.scss';

interface Props {
  offer: CarOffer;
  printTargetRef?: any;
}

const CarEquipmentTab: FC<Props> = ({ offer, printTargetRef }) => {
  const { t } = useTranslation();
  const { general } = useCarsApi();
  const includedCharges = offer.charges.filter((charge) => charge.included);
  const excludedCharges = offer.charges.filter((charge) => !charge.included);

  useEffect(() => {
    wsInfo('userInteraction', 'Open car equipment tab', {
      offerId: offer.offerId
    });
  }, []);

  return (
    <div className={styles.carDetails}>
      <span className={styles.carDetails__set}>
        <CarEquipmentItem
          legend={t('a_auto')}
          content={`${offer.name} ${t('sipp_or_similiar')}*`}
          description={`* ${t('sample_car_explanation')}`}
        />

        <ul
          className={classNames(
            styles.carDetails__detail,
            styles.carDetails__equipment,
            'carDetailsEquip'
          )}
        >
          <li className={styles.carDetails__item_container}>
            <CarEquipmentItem
              label={`${t('cartype')}`}
              labelIcon="automobile"
              content={`${ucFirst(t(offer.cartype))} (${offer.sipp})`}
            />
          </li>
          <li className={styles.carDetails__item_container}>
            <CarEquipmentItem
              label={t('carclass')}
              labelIcon="automobile"
              content={`${ucFirst(t(offer.carclass))}`}
            />
          </li>
          <li className={styles.carDetails__item_container}>
            <CarEquipmentItem
              label={`${t('fuel')}:`}
              labelIcon="gasoline-pump-3"
              content={t(`0nc_fuel_${offer.fuel}`, offer.fuel)}
            />
          </li>
          <li className={styles.carDetails__item_container}>
            <CarEquipmentItem
              label={`${t('a_people')}:`}
              labelIcon="user-o"
              content={`${offer.adults + offer.children}`}
            />
          </li>
          <li className={styles.carDetails__item_container}>
            <CarEquipmentItem
              label={`${t('gearbox')}:`}
              labelIcon={
                offer.equip.includes('automaticTransmission') ? 'automatic-gear' : 'manual-gear'
              }
              content={
                offer.equip.includes('automaticTransmission') ? t('0nc_gear_a') : t('0nc_gear_m')
              }
            />
          </li>
          {offer.doors && (
            <li className={styles.carDetails__item_container}>
              <CarEquipmentItem
                label={`${t('0nc_door_amount')}:`}
                labelIcon="doors"
                content={`${offer.doors}`}
              />
            </li>
          )}
          {offer.equip.includes('ac') && (
            <li className={styles.carDetails__item_container}>
              <CarEquipmentItem label={`${t('filters_ac')}:`} labelIcon="ac" contentIcon="tick" />
            </li>
          )}
          {offer.equip.includes('gps') && (
            <li className={styles.carDetails__item_container}>
              <CarEquipmentItem label={`${t('a_gps')}:`} labelIcon="nav" contentIcon="tick" />
            </li>
          )}
          {offer.equip.includes('wifi') && (
            <li className={styles.carDetails__item_container}>
              <CarEquipmentItem
                label={`${t('wifi', 'WIFI')}:`}
                labelIcon="wifi"
                contentIcon="tick"
              />
            </li>
          )}
          {offer.baggageLarge > 0 && (
            <li className={styles.carDetails__item_container}>
              <CarEquipmentItem
                label={`${t('a_kuffer')}:`}
                labelIcon="suitcase2"
                content={`${offer.baggageLarge}
                ${t('0nc_case_big')}${
                  offer.baggageSmall > 0 ? `, ${offer.baggageSmall} ${t('0nc_case_small')}` : ''
                }
                `}
              />
            </li>
          )}
          {offer.equip.includes('drive4W') && (
            <li className={styles.carDetails__item_container}>
              <CarEquipmentItem
                label={`${t('filters_4_wheel')}:`}
                labelIcon="awd"
                contentIcon="tick"
              />
            </li>
          )}
          {offer.equip.includes('additionalDriver') && (
            <li className={styles.carDetails__item_container}>
              <CarEquipmentItem
                label={`${t('additional_driver')}:`}
                labelIcon="user-plus"
                contentIcon="tick"
              />
            </li>
          )}
          {offer.equip.includes('noLocalFee') && (
            <li className={styles.carDetails__item_container}>
              <CarEquipmentItem
                label={`${t('local_tax_inc')}:`}
                labelIcon="money"
                contentIcon="tick"
              />
            </li>
          )}
          {offer.equip.includes('hasNoAirportFee') && (
            <li className={styles.carDetails__item_container}>
              <CarEquipmentItem
                label={`${t('0nc_airport_fees_included')}:`}
                labelIcon="money"
                contentIcon="tick"
              />
            </li>
          )}
        </ul>
      </span>

      <span className={styles.carDetails__set}>
        <CarEquipmentItem
          legend={t('0nc_partner')}
          description={t(`partner_${offer.partner.toLocaleLowerCase()}_info_content`)}
        >
          <div className={styles.carDetails__arroundLogo}>
            <PartnerLogo name={offer.partner} width={90} height={30} />
          </div>
        </CarEquipmentItem>
      </span>

      {offer.showSupplierLogo && offer.supplier && offer.supplier !== offer.partner && (
        <span className={styles.carDetails__set}>
          <CarEquipmentItem legend={t('0nc_supplier_txt')}>
            <div className={styles.carDetails__arroundLogo}>
              <PartnerLogo name={offer.supplier} width={90} height={30} />
            </div>
          </CarEquipmentItem>
        </span>
      )}

      {offer.products.length > 0 && (
        <span className={styles.carDetails__set}>
          <span className={styles.carDetails__legend}>{`${t('0nc_package')}`}</span>
          <ul>
            {offer.products.map((product, index) => (
              <li key={index}>
                <span className={styles.carDetails__item}>
                  <span className={styles.carDetails__item_value}>
                    {product.name}
                    <span className={styles.carDetails__item_product_price}>
                      <span>{priceFormat(product.price)}</span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: currencyToSymbol(product.currency)
                        }}
                      />
                    </span>
                  </span>
                </span>
                <span
                  className={styles.carDetails__description}
                  dangerouslySetInnerHTML={{
                    __html: t(product.descriptionTextId, product.description)
                  }}
                />
              </li>
            ))}
          </ul>
        </span>
      )}

      {includedCharges.length > 0 && (
        <span className={styles.carDetails__set}>
          <span className={styles.carDetails__legend}>{`${t('included_charges')}`}</span>
          <ul>
            {includedCharges.map((charge, index) => (
              <li key={index}>
                <span className={styles.carDetails__item}>
                  <span className={styles.carDetails__item_value}>
                    {t(charge.nameId, charge.description || charge.name)}
                    {charge.price > 0 && (
                      <span className={styles.carDetails__item_product_price}>
                        <span>{`${priceFormat(charge.price)} `}</span>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: currencyToSymbol(charge.currency)
                          }}
                        />
                      </span>
                    )}
                  </span>
                </span>
              </li>
            ))}
          </ul>
        </span>
      )}
      {excludedCharges.length > 0 && (
        <span className={styles.carDetails__set}>
          <span className={styles.carDetails__legend}>{`${t('vehicle_charges')}`}</span>
          <ul>
            {excludedCharges.map((charge, index) => (
              <li key={index}>
                <span className={styles.carDetails__item}>
                  <span className={styles.carDetails__item_value}>
                    {t(charge.nameId, charge.description || charge.name)}
                    {charge.price > 0 && (
                      <span className={styles.carDetails__item_product_price}>
                        <span>{`${priceFormat(charge.price)} `}</span>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: currencyToSymbol(charge.currency)
                          }}
                        />
                      </span>
                    )}
                  </span>
                </span>
              </li>
            ))}
          </ul>
        </span>
      )}

      {offer.status && (
        <span className={styles.carDetails__set}>
          <CarEquipmentItem
            legend={t('0nc_offer_status')}
            content={t(`0nc_offer_status_${offer.status.toLowerCase()}`)}
            description={t(`0nc_offer_status_${offer.status.toLowerCase()}_desc`)}
          />
        </span>
      )}

      {offer.prepaid && (
        <span className={styles.carDetails__set}>
          <CarEquipmentItem legend={t('0nc_offer_prepaid')} />
        </span>
      )}

      {offer.freeCancellationUntil && (
        <span className={styles.carDetails__set}>
          <CarEquipmentItem
            legend={t('extras_cancellation')}
            content={`${t('free_cancellation_until')} ${datetimeFormat(
              offer.freeCancellationUntil,
              'DD.MM.YYYY, HH:mm'
            )}`}
          />
        </span>
      )}
      {offer.deposit > 0 && (
        <span className={styles.carDetails__set}>
          <CarEquipmentItem legend={t('deposit')}>
            <span
              dangerouslySetInnerHTML={{
                __html: combineString(t('deposit_warning'), [
                  ReactDOMServer.renderToStaticMarkup(
                    <strong>
                      <Price
                        className={styles.deposit__price}
                        price={offer.deposit}
                        currency={offer.depositCurrency || general.currency}
                        inline
                      />
                    </strong>
                  )
                ])
              }}
            />
          </CarEquipmentItem>
        </span>
      )}
      {offer.deposit === 0 && (
        <span className={styles.carDetails__set}>
          <CarEquipmentItem legend={t('deposit')}>
            <CarIcon icon="tick" size="12px" className="icon__levelSuccess" />
            {t('filter_no_deposit')}
          </CarEquipmentItem>
        </span>
      )}
      {offer.fuelPolicy && (
        <span className={styles.carDetails__set}>
          <CarEquipmentItem
            legend={t('fuel_policy')}
            content={t(`fuel_policy_${offer.fuelPolicy.toLowerCase()}`, offer.fuelPolicy)}
            description={t(`fuel_policy_${offer.fuelPolicy.toLowerCase()}_desc`)}
          />
        </span>
      )}

      {offer.includedKm === 0 && (
        <span className={styles.carDetails__set}>
          <CarEquipmentItem legend={t('0nc_limited_km')} content={t('0nc_unlimited_km')} />
        </span>
      )}

      {offer.includedKm > 0 && (
        <span className={styles.carDetails__set}>
          <CarEquipmentItem legend={t('0nc_limited_km')} content={limitedKmLabel(offer, t)} />
        </span>
      )}

      {offer.includedKm < 0 && (
        <span className={styles.carDetails__set}>
          <CarEquipmentItem legend={t('0nc_limited_km')} content={t('additional_km_cost')} />
        </span>
      )}

      {(offer.minAge > 0 || offer.maxAge > 0) && (
        <span className={styles.carDetails__set}>
          <CarEquipmentItem legend={t('driver_age_restrictions')}>
            <ul>
              {offer.minAge > 0 && (
                <li>
                  <span className={styles.carDetails__item}>
                    <span className={styles.carDetails__item_value}>
                      {`${t('min_driver_age')}: ${offer.minAge}`}
                    </span>
                  </span>
                </li>
              )}
              {offer.maxAge > 0 && (
                <li>
                  <span className={styles.carDetails__item}>
                    <span className={styles.carDetails__item_value}>
                      {`${t('max_driver_age')}: ${offer.maxAge}`}
                    </span>
                  </span>
                </li>
              )}
            </ul>
          </CarEquipmentItem>
        </span>
      )}

      <PrintWrapper printTargetRef={printTargetRef} />
    </div>
  );
};

CarEquipmentTab.defaultProps = {
  printTargetRef: null
};

export default CarEquipmentTab;
